jQuery(document).ready(function ($) {
  // tooltips
  $('main').tooltips();

  // responsive videos
  $('header.landing-page.video, section.landing-page.video, main').responsivevideos();

  // TOGGLE MOBILE NAV
  $('.btn-mobile-open-nav').click(function () {
    $('.mobile-menu').toggle();
    $('span').toggleClass('menu-open');
    $('span').toggleClass('menu-close');
  });

  $('.li1.has_children').on('click', function () {
    $(this).find('li').toggleClass('show');
  });

  $('.btn-mobile-open-nav').on('click', function () {
    $('.content-wrapper').toggleClass('fixed');
  });

  $(function () {
    $('.li1.has_children span').attr('tabindex', function (index, attr) {
      return 0;
    });
    $('.li1.has_children.a1').attr('tabindex', function (index, attr) {
      return -1;
    });
  });

  $(function () {
    $('.btn-mobile-open-nav').keypress(function (e) {
      if (e.keyCode == 13 || e.keyCode == 32) {
        e.preventDefault();
        $('.mobile-menu').toggle();
        $('span').toggleClass('menu-open');
        $('span').toggleClass('menu-close');
      }
    });
  });

  $(function () {
    $('.li1.has_children').keypress(function (e) {
      if (e.keyCode == 13 || e.keyCode == 32) {
        e.preventDefault();
        $(this).toggleClass('minus');
        $(this).find('li').toggle();
      }
    });
  });

  //Span added to nav items with children
  $('.mobile-menu .ul1 > li').each(function () {
    if ($(this).hasClass('has_children')) {
      $('> a', this).after('<span class="toggler" tabindex="0" aria-label=""><span class="fa fa-plus"></span></span>');
      $('span.toggler', this).attr("aria-label", "View Child Pages for " + $('> a', this).text());
    }
  });

  $('.li1.has_children span').on('click', function () {
    $(this).children('span.fa').toggleClass('fa-minus');
  });

  $(function () {
    $('.li1.has_children span').keypress(function (e) {
      if (e.keyCode == 13 || e.keyCode == 32) {
        e.preventDefault();
        $(this).children('span span.fa').toggleClass('fa-minus');
        $(this).find('li').toggle();
      }
    });
  });
  //Span added to nav items with children END

  //Span added to sidebar nav items with children
  $('nav.sub-navigation .ul1 > li').each(function () {
    if ($(this).hasClass('has_children')) {
      $('> a', this).after('<span tabindex="0" aria-label="View Child Pages"><i class="fa fa-plus"></i></span>');
    }
  });

  $('.li1.has_children span').on('click', function () {
    $(this).children('span i').toggleClass('fa-minus');
  });

  $(function () {
    $('.li1.has_children span').keypress(function (e) {
      if (e.keyCode == 13 || e.keyCode == 32) {
        e.preventDefault();
        $(this).children('span i').toggleClass('fa-minus');
        $(this).find('li').toggle();
      }
    });
  });
  //Span added to sidebar nav items with children END

  //Sidebar navigation for Default subpage
  $('nav.sub-navigation.default .ul1 > li').each(function () {
    if ($(this).has('ul.ul2 > li').length) {
      $('> a', this).after('<div class="view-more" tabindex="0" aria-label="View Child Pages"><span class="fa fa-' +
        ($(this).hasClass('active') ? 'minus' : 'plus') + '"></span></div>');
      $('.view-more', this).attr("aria-label", "View Child Pages for " + $('> a', this).text());
    }
  });

  // $('.ul1 li .view-more').click(function () {
  //   var parentLi = $(this).parent('li');
  //   parentLi.toggleClass('open');
  //   $(this).next(".ul2").slideToggle();
  //   if (parentLi.hasClass('open')) {
  //     $(this).find('.fa').removeClass('fa-plus').addClass('fa-minus');
  //   } else {
  //     $(this).find('.fa').removeClass('fa-minus').addClass('fa-plus');
  //   }
  //   //console.log('slidetoggle');
  // });

  
  $(".ul1 li .view-more").click(function () {
    $(this).closest("li").siblings().find(".view-more").removeClass("open").next(".ul2").slideUp();
    $(this).closest("li").siblings().find(".fa").removeClass('fa-minus').addClass('fa-plus');
    $(this).toggleClass("open").next(".ul2").slideToggle();
    if ($(this).hasClass('open')) {
      $(this).find('.fa').removeClass('fa-plus').addClass('fa-minus');
    } else {
      $(this).find('.fa').removeClass('fa-minus').addClass('fa-plus');
    }
  });

  $(function () {
    $('.ul1 li .view-more').keypress(function (e) {
      if (e.keyCode == 13 || e.keyCode == 32) {
        e.preventDefault();
        $(this).next(".ul2").slideToggle();
      }
    });
  });
  //Sidebar navigation for Default subpage END

  //Main sub navigation toggle
  $('button.view-more').on('click', function () {
    $('.nav-items').toggleClass('open');
    $(this).toggleClass('open');
  });
  //END Main sub navigation toggle

  // add class to select on open
  // $('select').on('change', function () {
  //   $(this).toggleClass('open');
  // });
  // $('label').on('click', function (e) {
  //   if ($(this).next().is('select')) {
  //     e.preventDefault();
  //     $(this).next('select').focus();
  //   }
  // });
  // $(document).on('keyup', function (e) {
  //   if (e.keyCode === 27) {
  //     if ($('select').hasClass('open')) {
  //       $(this).removeClass('open');
  //     }
  //   }
  // });
  $("select").on({
    'focus': function () {
      $(this).addClass('open');
    },
    "blur": function () {
      $(this).removeClass('open');
    },
    "keyup": function (e) {
      if (e.keyCode === 27)
        if ($('select').hasClass('open')) {
          $(this).removeClass('open');
        }
    }
  });
  $(window).on('scroll', function () {
    if ($('select').hasClass('open')) {
      $('select').removeClass('open');
    }
  });

  // learn more widget expand

  $('.sidebar .learn-more ul li').each(function (i) {
    if (!$(this).is(':visible')) {
      $(this).addClass('toggle');
    }
  });
  $('.sidebar .learn-more .more-learn-more a').click(function () {
    var text = $(this).text();
    $(this).text(text == "View More" ? "View Less" : "View More");
    $('.sidebar .learn-more ul li.toggle').slideToggle();

  });


  // hash focus onload
  if (document.location.hash) {
    var myAnchor = document.location.hash;
    $(myAnchor).attr('tabindex', -1).on('blur focusout', function () {
      $(this).removeAttr('tabindex');
    }).focus();
  }

  // hash focus inline
  $(window).bind('hashchange', function () {
    var hash = "#" + window.location.hash.replace(/^#/, '');
    if (hash != "#") {
      $(hash).attr('tabindex', -1).on('blur focusout', function () {
        $(this).removeAttr('tabindex');
      }).focus();
    }
    else {
      $("#headcontainer").attr('tabindex', -1).on('blur focusout', function () {
        $(this).removeAttr('tabindex');
      }).focus();
    }
  });
  // Accordions
  $('.accordions h3').each(function () {
    var $this = $(this);

    // create unique id for a11y relationship
    var id = 'accordion-' + $this.index();

    // wrap the content and make it focusable
    $this.nextUntil('h3').wrapAll('<div id="' + id + '" aria-hidden="true">');
    var panel = $this.next();

    // Add the button inside the <h2> so both the heading and button semanics are read
    $this.wrapInner('<button aria-expanded="false" aria-controls="' + id + '">');
    var button = $this.children('button');

    // Toggle the state properties
    button.on('click', function (e) {
      e.preventDefault();
      var state = $(this).attr('aria-expanded') === 'false' ? true : false;
      $(this).attr('aria-expanded', state);
      $(this).parent('h3').toggleClass('is-active');
      panel.attr('aria-hidden', !state);
    });
  });

  jQuery(document).ready(function ($) {
    var tabArray = [];

    var tabGroups = function () {
      var r = $.Deferred();
      $('.tabs').each(function () {
        tabContainerID = 'tabgroup' + $(this).index();
        tabGroup = $('.tabgroup#' + tabContainerID);
        $(this).attr('id', tabContainerID);
        var id = $(this).attr('id');
        tabArray.push(id);
      });
      return r;
    };

    var tabItems = function () {
      $.each(tabArray, function (index, value) {
        var tabContainer = $('.tabs#' + value);
        var wrapTabs = $('.tab-con', tabContainer);
        // The setup
        $('ul.tab-list', tabContainer).empty().attr('role', 'tablist');
        $('.tab-content', tabContainer).each(function () {
          var tabHeading = $(this).find("> .tab-title").html();
          var tabHeadingID = value + 'tab' + $(this).index();
          $('ul.tab-list', tabContainer).append('<li><a href="#' + tabHeadingID + '">' + tabHeading + '</a></li>');
          $(this).attr('id', tabHeadingID);
        });
        $('[role="tablist"] > li', tabContainer).attr('role', 'presentation');
        $('[role="tablist"] a', tabContainer).attr({
          'role': 'tab',
          'tabindex': '-1'
        });
        // Make each aria-controls correspond id of targeted section (re href)
        $('[role="tablist"] a', tabContainer).each(function () {
          $(this).attr('aria-controls', $(this).attr('href').substring(1));
        });
        // Make the first tab selected by default and allow it focus
        $('[role="tablist"] li:first-child a', tabContainer).attr({
          'aria-selected': 'true',
          'tabindex': '0'
        });
        // Make each section focusable and give it the tabpanel role
        $('section', tabContainer).attr({
          'role': 'tabpanel'
        });
        // Make first child of each panel focusable programmatically
        $('section > *:first-child', tabContainer).attr({
          'tabindex': '0'
        });
        // Make all but the first section hidden (ARIA state and display CSS)
        $('[role="tabpanel"]:not(:first-of-type)', tabContainer).attr({
          'aria-hidden': 'true'
        });
        // Change focus between tabs with arrow keys
        $('[role="tab"]', tabContainer).on('keydown', function (e) {
          // define current, previous and next (possible) tabs
          var tabCurrent = $(this);
          var tabPrev = $(this).parents('li').prev().children('[role="tab"]');
          var tabNext = $(this).parents('li').next().children('[role="tab"]');
          var tabTarget;
          // find the direction (prev or next)
          switch (e.keyCode) {
            case 37:
              tabTarget = tabPrev;
              break;
            case 39:
              tabTarget = tabNext;
              break;
            default:
              tabTarget = false;
              break;
          }
          if (tabTarget.length) {
            tabCurrent.attr({
              'tabindex': '-1',
              'aria-selected': null
            });
            tabTarget.attr({
              'tabindex': '0',
              'aria-selected': true
            }).focus();
          }
          // Hide panels
          $('[role="tabpanel"]', tabContainer).attr('aria-hidden', 'true');
          // Show panel which corresponds to target
          $('#' + $(document.activeElement).attr('href').substring(1)).attr('aria-hidden', null);
        });
        // Handle click on tab to show + focus tabpanel
        $('[role="tab"]', tabContainer).on('click', function (e) {
          e.preventDefault();
          var tabID = $(this).attr('href');
          // remove focusability and aria-selected
          $('[role="tab"]', tabContainer).attr({
            'tabindex': '-1',
            'aria-selected': null
          });
          // replace above on clicked tab
          $(this).attr({
            'aria-selected': true,
            'tabindex': '0'
          });
          // Hide panels
          $('[role="tabpanel"]', tabContainer).attr('aria-hidden', 'true');
          // show corresponding panel
          $('section' + tabID).attr('aria-hidden', null);
        });

        var tabsContainerWidth = $('.tab-con', tabContainer).outerWidth();
        var tabsWidth = $('ul.tab-list', tabContainer).outerWidth();
        var scrollWidth = tabsWidth - tabsContainerWidth;

        if (tabsWidth > tabsContainerWidth) {
          tabContainer.addClass('scroll-right');
          wrapTabs.on('scroll', function () {
            if (wrapTabs.scrollLeft() >= tabsWidth - tabsContainerWidth) {
              tabContainer.removeClass('scroll-right');
            } else {
              tabContainer.addClass('scroll-right');
            }
            if (wrapTabs.scrollLeft() > 1) {
              tabContainer.addClass('scroll-left');
            } else {
              tabContainer.removeClass('scroll-left');
            }
          });
        }
      });
    };
    tabGroups().done(tabItems());
  });

  // // Handle click on Mobile tabs to show + focus tabpanel
  // $('.mobile-tab').on('click', function (e) {
  //     e.preventDefault();

  //     // remove focusability [sic] and aria-selected
  //     $('.mobile-tab').attr({
  //         'tabindex': '0',
  //         'aria-selected': null
  //     });


  //     // replace above on clicked tab
  //     $(this).attr({
  //         'aria-selected': true,
  //         'tabindex': '0'
  //     });

  //     // Hide panels
  //     $('[role="tabpanel"]').attr('aria-hidden', 'true');

  //     // show corresponding panel
  //     $('#' + $(this).attr('href').substring(1))
  //         .attr('aria-hidden', null);

  // });

  // // Make first child of each panel focusable programmatically
  // $('.mobile-tab:first-of-type').attr({
  //     'aria-selected': 'true'
  // });

  //SEARCH FUNCTIONALITY START - DESKTOP
  $(document).ready(function () {
    $('header .search button').on('click', function () {
      var query = $('.search .input-group-field').val().replace(/&/g, '%26');
      window.location.href = "/Search/?q=" + query;
      return false;
    });

    $('header .search input').keypress(function (e) {
      if (e.which == 13) {
        var query = $('.search .input-group-field').val().replace(/&/g, '%26');
        window.location.href = "/Search/?q=" + query;
        return false;
      }
    });
  });
  //SEARCH FUNCTIONALITY - DESKTOP END

  //SEARCH FUNCTIONALITY START - MOBILE
  $(document).ready(function () {
    $('.mobile-search-box button').on('click', function () {
      var query = $('.mobile-search-box input').val().replace(/&/g, '%26');
      window.location.href = "/Search/?q=" + query;
      return false;
    });

    $('.mobile-search-box input').keypress(function (e) {
      if (e.which == 13) {
        var query = $('.mobile-search-box input').val().replace(/&/g, '%26');
        window.location.href = "/Search/?q=" + query;
        return false;
      }
    });
  });
  //SEARCH FUNCTIONALITY - MOBILE END

  //Toggle Text after 145 characters
  $(".learn-more-card .details p").each(function () {
    if ($(this).text().length < 145) return;
    $(this).html(
      $(this).text().slice(0, 145) + '<span>... </span><span class="more">More</span>' +
      '<span style="display:none;">' + $(this).text().slice(145, $(this).text().length) + ' <span class="less">Less</span></span>'
    );
  });

  $('span.more', $(".learn-more-card .details p")).click(function (event) {
    event.preventDefault();
    $(this).hide().prev().hide();
    $(this).next().show();
  });

  $('span.less', $(".learn-more-card .details p")).click(function (event) {
    event.preventDefault();
    $(this).parent().hide().prev().show().prev().show();
  });
  //Toggle Text after 145 characters END

  //Accordion
  var link = $(".accordion .show-hide");
  link.on('click', function (e) {
    e.preventDefault();
    var a = $(this).prev('.content');
    $(a).slideToggle('fast');
    var txt = $(this).hasClass('active') ? 'Show More' : 'Show Less';
    $(this).toggleClass('active').text(txt);
  });
  //END Accordion

  // get country code and display bar based on location
  $.get("https://ipinfo.io", function (response) {
    if (response.country == "US") {
      $("#cookie-bar").css("display", "none");
    }
    else if (response.country == "GB") {
      if ($.cookie("dismiss") == null) {
        $("#cookie-bar").slideDown();
      }
    }
    else {
      if ($.cookie("dismiss") == null) {
        $("#cookie-bar").slideDown();
      }
    }
  }, "jsonp");

  // Click Accept button then bar slides up and cookifies so bar won't show again
  $(".dismiss").click(function (e) {
    document.cookie = "dismiss=true; expires=Fri, 31 Dec 9999 23:59:59 UTC";
    e.preventDefault();
    $("#cookie-bar").slideUp();
  });

  // Open cookie popup
  $("#open-popup").click(function (e) {
    e.preventDefault();
    $(".cookie-popup").addClass('active');
  });

  // Close popup when x is clicked
  $("#close-btn").click(function () {
    $(".cookie-popup").removeClass('active');
  });

  //Learn More Filters
  $('.learn-more-filter').on('click', function () {
    $(this).find('ul.filter-items').toggle();
    $(this).find('.filter').toggleClass('active');
  });

  //Blog Subscribe
  $('.subscribe-link').on('click', function () {
    $('.subscribe.widget').toggle();
  });

  $('.subscribe.widget .close').on('click', function () {
    $('.subscribe.widget').toggle();
  });

  //Focus Tabbing through main navigation with sub menu
  $(".li1.has_children").focusin(function () {
    $(this).find(".ul2").show();
    $(".li2.has_children").focusin(function () {
      $(this).find(".ul3").show();
      $(".li2.has_children").focusout(function () {
        $(this).find(".ul3").removeAttr("style");
      });
    });
    $(".li1.has_children").focusout(function () {
      $(this).find(".ul2").removeAttr("style");
    });
  });

  //Scroll to Top
  $(window).scroll(function () {
    if ($(this).scrollTop() > 250) {
      $(".scroll-to-top").css("opacity", "1");
    } else {
      $(".scroll-to-top").css("opacity", "0");
    }
  });
  $(".scroll-to-top").click(function () {
    $('html, body').animate({
      scrollTop: 0
    }, 1000);
    return false;
  });

  //If Cirrato is in the URL then change Request a Demo URL, same for Drivve but add another button
  $(document).ready(function () {
    if (window.location.href.indexOf("cirrato") > -1) {
      $('.btn-request-demo a').attr({ href: '/products/cirrato/cirrato-request-a-demo/', title: 'Request a demo for Cirrato products' });
    } else if (window.location.href.indexOf("drivve-products") > -1) {
      $('.btn-request-demo a').attr({ href: '/products/drivve-products/drivve-request-a-demo/', title: 'Request a demo for Drivve products' });
      $('.btn-request-demo:first-child').css('margin-bottom', '10px');
      $('.btn-request-demo').after('<div class="btn-request-news"><a href="/products/drivve-products/Sign-up-for-Drivve-Newsletter/">Newsletter Registration</a></div>');
    }
  });

  //Click Dimensions Remove Empty Cells
  $(window).on('load', function () {
    $('.emptyCell').css({ 'display': 'none' });
  });

  // documentation images
  $.each($('section.main img'), function () {
    var imageSource = $(this).attr('src');
    $(this).attr('src', imageSource);
    $(this).wrap("<a href=" + "'" + $(this).attr('src') + "'" + " class='image-link'></a>");
  });
  $('.image-link').magnificPopup({ type: 'image' });
  // documentation sticky sidebar
  // $('.sidebar.deco').stickySidebar({ topSpacing: 20 });

  // documentation location
  $.urlParam = function (name) {
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
    return results[1] || 0;
  };

  var pageexists = 'pageid';
  var sectioexisits = 'sectionid';
  var url = window.location.href;

  if (url.indexOf('?' + pageexists + '=') != -1) {
    var activepageid = $.urlParam('pageid');
    $('#pagebackground-' + activepageid).removeClass("list-group-mine");
    $('#page-' + activepageid).addClass("list-group-item-info");
    if (url.indexOf('&' + sectioexisits + '=') != -1) {
      var activesectionid = $.urlParam('sectionid');
      $('#section-' + activesectionid).addClass("list-group-item-success");
    }
  }

  // Input Masks
  // $('input').inputmask();

  // Sign Up Country


});