// If the namespace object isn’t defined, create it
if (typeof(LRSACCOUNTFORM) === "undefined") {
    LRSACCOUNTFORM = {
        __namespace: true,

        states: [
            "AP", "AE", "AA", "AK", "AL", "AR", "AS", "AZ", "CA", "CO", "CT", "DC", "DE", "FL", "FM", "GA", "GU", "HI",
            "IA", "ID", "IL", "IN", "KS", "KY", "LA", "MA", "MD", "ME", "MH", "MI", "MN", "MO", "MP", "MS", "MT", "NC",
            "ND", "NE", "NH", "NJ", "NM", "NV", "NY", "OH", "OK", "OR", "PA", "PR", "PW", "RI", "SC", "SD", "TN", "TX",
            "UT", "VA", "VI", "VT", "WA", "WI", "WV", "WY"
        ],
        can_provinces: ["Alberta", "British Columbia", "Manitoba", "New Brunswick", "Newfoundland and Labrador", "Nova Scotia", "Northwest Territories", "Nunavut", "Ontario",
            "Prince Edward Island", "Quebec", "Saskatchewan", "Yukon"],
        aus_provinces: ["New South Wales", "Queensland", "South Australia", "Victoria", "Western Australia", "Australian Capital Territory", "Northern Territory"],
        mex_provinces: ["Aguascalientes", "Baja California", "Baja California Sur", "Campeche", "Chiapas", "Chihuahua", "Coahuila", "Colima",
            "Durango", "Federal District (Mexico City)", "Guanajuato", "Guerrero", "Hidalgo", "Jalisco", "Michoacán", "Morelos", "México", "Nayarit", "Nuevo León",
            "Oaxaca", "Puebla", "Querétaro", "Quintana Roo", "San Luis Potosí", "Sinaloa", "Sonora", "Tabasco", "Tamaulipas", "Tlaxcala", "Veracruz", "Yucatán", "Zacatecas"],
        italy_provinces: ["Bolzano", "Cremona", "Cuneo", "Forlì-Cesena", "Milano", "Padova", "Parma", "Pavia", "Pordenone", "Reggio Emilia", "Rome", "Torino", "Trieste", "Verona", "Vicenza"],
        countries: ["USA", "Canada", "Australia", "Mexico", "Italy"],
        usOptionSetCode: "1",
        caOptionSetCode: "2",
        ausOptionSetCode: "12",
        mexOptionSetCode: "131",
        italyOptionSetCode: "99",

        onChange_CountryPickList: function (countryPickList,
            statePickList,
            countryField,
            stateField,
            oldStateSection,
            newStateSection,
            stateTab,
            isStateRequired) {
            var selection = LRSFORMLIBD365.getFieldValue(countryPickList);
            LRSFORMLIBD365.updateField(stateField, "");
            LRSFORMLIBD365.updateField(statePickList, null);
            if (selection == LRSFORMLIBD365.usOptionSetCode) {
                LRSFORMLIBD365.updateField(countryField, "USA");
                if (isStateRequired == true) {
                    LRSFORMLIBD365.getField(statePickList).setRequiredLevel("required");
                    LRSFORMLIBD365.getField(stateField).setRequiredLevel("none");
                }
                LRSFORMLIBD365.hideField(stateField);
                LRSFORMLIBD365.showField(statePickList);
                LRSFORMLIBD365.changeStatePickListOptions(LRSFORMLIBD365.usOptionSetCode, statePickList, stateField);
            } else if (selection == LRSFORMLIBD365.caOptionSetCode) {
                LRSFORMLIBD365.updateField(countryField, "Canada");
                if (isStateRequired == true) {
                    LRSFORMLIBD365.getField(statePickList).setRequiredLevel("required");
                    LRSFORMLIBD365.getField(stateField).setRequiredLevel("none");
                }
                LRSFORMLIBD365.hideField(stateField);
                LRSFORMLIBD365.showField(statePickList);
                LRSFORMLIBD365.changeStatePickListOptions(LRSFORMLIBD365.caOptionSetCode, statePickList, stateField);
            } else if (selection == LRSFORMLIBD365.ausOptionSetCode) {
                LRSFORMLIBD365.updateField(countryField, "Australia");
                if (isStateRequired == true) {
                    LRSFORMLIBD365.getField(statePickList).setRequiredLevel("required");
                    LRSFORMLIBD365.getField(stateField).setRequiredLevel("none");
                }
                LRSFORMLIBD365.hideField(stateField);
                LRSFORMLIBD365.showField(statePickList);
                LRSFORMLIBD365.changeStatePickListOptions(LRSFORMLIBD365.ausOptionSetCode, statePickList, stateField);
            } else if (selection == LRSFORMLIBD365.mexOptionSetCode) {
                LRSFORMLIBD365.updateField(countryField, "Mexico");
                if (isStateRequired == true) {
                    LRSFORMLIBD365.getField(statePickList).setRequiredLevel("required");
                    LRSFORMLIBD365.getField(stateField).setRequiredLevel("none");
                }
                LRSFORMLIBD365.hideField(stateField);
                LRSFORMLIBD365.showField(statePickList);
                LRSFORMLIBD365.changeStatePickListOptions(LRSFORMLIBD365.mexOptionSetCode, statePickList, stateField);
            } else if (selection == LRSFORMLIBD365.italyOptionSetCode) {
                LRSFORMLIBD365.updateField(countryField, "Italy");
                if (isStateRequired == true) {
                    LRSFORMLIBD365.getField(statePickList).setRequiredLevel("required");
                    LRSFORMLIBD365.getField(stateField).setRequiredLevel("none");
                }
                LRSFORMLIBD365.hideField(stateField);
                LRSFORMLIBD365.showField(statePickList);
                LRSFORMLIBD365.changeStatePickListOptions(LRSFORMLIBD365.italyOptionSetCode, statePickList, stateField);
            } else {
                if (LRSFORMLIBD365.getField(countryPickList).getSelectedOption() != null) {
                    LRSFORMLIBD365.updateField(countryField,
                        LRSFORMLIBD365.getField(countryPickList).getSelectedOption().text);
                } else {
                    LRSFORMLIBD365.updateField(countryField, null);
                }
                if (isStateRequired == true) {
                    LRSFORMLIBD365.getField(statePickList).setRequiredLevel("none");
                    LRSFORMLIBD365.getField(stateField).setRequiredLevel("required");
                }
                LRSFORMLIBD365.hideField(statePickList);
                LRSFORMLIBD365.showField(stateField);
            }
        },
        onChange_StatePickList: function (statePickList, stateField) {
            if (LRSFORMLIBD365.getField(statePickList).getValue() != null) {
                LRSFORMLIBD365.updateField(stateField, LRSFORMLIBD365.getField(statePickList).getSelectedOption().text);
            } else {
                LRSFORMLIBD365.updateField(stateField, "");
            }
        },

        changeStatePickListOptions: function (optionSet, statePickList, stateField) {
            if (optionSet == 1) {
                LRSFORMLIBD365.setDynamicPicklistOptionsList(statePickList, stateField, LRSFORMLIBD365.states, false);
            } else if (optionSet == 2) {
                LRSFORMLIBD365.setDynamicPicklistOptionsList(statePickList, stateField, LRSFORMLIBD365.can_provinces, false);
            } else if (optionSet == 12) {
                LRSFORMLIBD365.setDynamicPicklistOptionsList(statePickList, stateField, LRSFORMLIBD365.aus_provinces, false);
            } else if (optionSet == 131) {
                LRSFORMLIBD365.setDynamicPicklistOptionsList(statePickList, stateField, LRSFORMLIBD365.mex_provinces, false);
            } else if (optionSet == 99) {
                LRSFORMLIBD365.setDynamicPicklistOptionsList(statePickList, stateField, LRSFORMLIBD365.italy_provinces, false);
            }
        },
        //Set the dynamic options
        setDynamicPicklistOptionsList: function (picklistName, fieldName, optList, allowBlank) {
            var cntrl = LRSFORMLIBD365.getControl(picklistName);
            var picklist = LRSFORMLIBD365.getAttribute(picklistName);
            if (!picklist.originalPicklistOptions) {
                // initilize array (this is being triggered before the onLoad by a business rule)
                picklist.originalPicklistOptions = [];
            }
            var origPicklistVal = LRSFORMLIBD365.getFieldValue(picklistName);
            var origFieldVal = LRSFORMLIBD365.getFieldValue(fieldName);
            //Clear out the option list
            cntrl.clearOptions();
            //Add a blank if nessesary
            if (allowBlank) {
                cntrl.addOption(picklist.originalPicklistOptions[0]);
            }
            // Loop through and add options
            for (var k = 0; k < optList.length; k++) {
                for (var i = 0; i < picklist.originalPicklistOptions.length; i++) {
                    if (picklist.originalPicklistOptions[i].text == optList[k]) {
                        cntrl.addOption(picklist.originalPicklistOptions[i]);
                        break;
                    }
                }
            }
            //Set the existing values
            LRSFORMLIBD365.setFieldValue(picklistName, origPicklistVal);
            LRSFORMLIBD365.setFieldValue(fieldName, origFieldVal);
        }

    }
}
