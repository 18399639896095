// // tooltips
// // console.log(scrollOffset);
(function ($) {
  $.fn.tooltips = function () {

    $('.tooltip').each(function () {
      var tooltip = $(this);
      var tooltipLoad = $(this).attr('data-tooltip-onload');
      var tooltipLink = $('[role="tooltip"]' + '[aria-describedby="' + $(this).attr('id') + '"]');
      var tooltipPrev = $(this).prev();
      var tooltipPrevPosition = $(tooltipPrev).css('position');
      var tooltipWidth = $(this).attr('data-tooltip-width');

      // set tooltip width
      $(this).css('width', tooltipWidth);
      // move tooltip into tooltip link
      $(this).appendTo($(this).prev());

      // prevent tooltip trigger clicks
      $(tooltipLink).on('click', function (e) {
        e.preventDefault();
      });

      // set tooltip link position
      if (tooltipPrevPosition !== 'relative' || tooltipPrevPosition !== 'absolute' || tooltipPrevPosition !== 'fixed') {
        $(tooltipPrev).css('position', 'relative');
      }

      // add close button to onload tooltip
      if (tooltipLoad === 'true') {
        $(this).append('<span class="tooltip-close" aria-label="Close tooltip"></span>');
      }

      // close onload tooltip when clicking close
      $(tooltipLink, '.tooltip-close').on('click', function (e) {
        e.preventDefault();
        $(tooltip).attr('aria-hidden', 'true');
      });

      // show tooltip on mouseover
      $(tooltipLink).on('mouseover', function (e) {
        e.preventDefault();
        if (tooltipLoad !== 'true') {
          $(tooltip).attr('aria-hidden', 'false');
        }
      });

      // hide tooltip on mouseout
      $(tooltipLink).on('mouseout', function (e) {
        e.preventDefault();

        if (tooltipLoad !== 'true') {
          $(tooltip).attr('aria-hidden', 'true');
        }
      });

      // show tooltip on focus
      $(tooltipLink).on('focus', function (e) {
        e.preventDefault();

        if (tooltipLoad !== 'true') {
          $(tooltip).attr('aria-hidden', 'false');
        }
      });

      // hide tooltip on focusout
      $(tooltipLink).on('focusout', function (e) {
        e.preventDefault();

        if (tooltipLoad !== 'true') {
          $(tooltip).attr('aria-hidden', 'true');
        }
      });

      // show tooltip when enter is pressed
      $(tooltipLink).on('keyup', function (e) {
        e.preventDefault();

        if (e.keyCode === 13) {
          $(tooltip).attr('aria-hidden', 'false');
        }
      });

      // hide tooltip when escape is pressed
      $(document).on('keyup', function (e) {
        e.preventDefault();
        if (e.keyCode === 27) {
          if ($('.tooltip').is(':visible')) {
            $('.tooltip').attr('aria-hidden', 'true');
          }
        }
      });
    });

  };
}(jQuery));